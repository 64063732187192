<template>
  <div
    v-bind:class="[
      this.$route.name == 'LeadDetail' ? 'top-back' : 'header-area',
    ]"
  >
    <div class="row">
      <!-- title -->
      <div class="col-xl-3">
        <div class="title" v-if="this.$route.name != 'LeadDetail'">
          <h2>
            <span class="mob" v-on:click="toggleSidebar('mob')"
              ><img class="img-fluid" src="../assets/img/menu.svg" alt=""
            /></span>
            <span class="menuwidth" v-on:click="toggleSidebar('web')"
              ><img class="img-fluid" src="../assets/img/menu.svg" alt=""
            /></span>
            <span v-if="title">{{ title }}</span>
            <span
              v-else-if="
                this.$route.meta.agentPageTitle &&
                $storage.get('auth').user.user_type == 2
              "
              >{{ this.$route.meta.agentPageTitle }}</span
            >
            <span v-else
              >{{ this.$route.meta.pageTitle }}
              <span v-if="$storage.get('auth').user.user_type == 1">
                <span class="navbar dealerdrop">
                  <span class="dropdown">
                    <a
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      class="dropdown-toggle"
                      >{{ $storage.get("auth").user.dealer_name }}</a
                    >
                    <span
                      aria-labelledby="navbarDropdown"
                      class="dropdown-menu"
                    >
                      <a
                        href="#"
                        v-for="dealer in dealers"
                        :key="dealer.da_id"
                        class="dropdown-item"
                        @click="updateSelectedDealer(dealer)"
                        >{{ dealer.dl_name }}</a
                      >
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </h2>
        </div>
        <div class="back-lead" v-if="this.$route.name == 'LeadDetail'">
          <router-link :to="{ name: 'Leads' }"
            ><span class="mob"
              ><img class="img-fluid" src="../assets/img/menu.svg" alt=""
            /></span>
            <img
              class="img-fluid arrow"
              src="../assets/img/27. Left.svg"
              alt=""
            />
            Back to Leadsheets
          </router-link>
        </div>
      </div>
      <!-- title -->
      <!-- notification -->
      <div class="col-xl-9">
        <div class="notification">
          <ul>
            <li @click="callService()" v-if="this.$route.name == 'LeadDetail'">
              <a
                class="deks"
                data-toggle="tooltip"
                title="Mark As Service Lead"
                href="#"
                ><img
                  class="img-fluid"
                  src="../assets/img/setting.svg"
                  alt=""
                />
              </a>
            </li>
            <li
              v-if="
                (this.$route.name == 'LeadDetail' &&
                  this.$storage.get('auth').user.user_type == 1) ||
                this.$storage.get('auth').user.permissions.indexOf('15') != -1
              "
            >
              <a
                v-if="
                  this.$storage
                    .get('auth')
                    .user.dealer_permissions.indexOf('4') != -1
                "
                href="#"
                data-toggle="tooltip"
                title="Desking"
                class="deks"
                @click="goToDesking"
                ><img class="img-fluid" src="../assets/img/desking.svg" alt=""
              /></a>
            </li>
            <li v-if="this.$route.name == 'LeadDetail'">
              <a
                @click="openTouchModal"
                class="hands"
                data-toggle="tooltip"
                title="Touches"
                href="#"
                ><img class="img-fluid" src="../assets/img/stop.svg" alt="" />
                <span>{{ this.$store.state.touches }}</span></a
              >
            </li>
            <li v-if="this.$route.name == 'LeadDetail'">
              <a
                @click="openEngModal"
                data-toggle="tooltip"
                title="Engagements"
                class="hands"
                href="#"
                ><img
                  class="img-fluid"
                  src="../assets/img/hand-stop.svg"
                  alt=""
                />
                <span>{{ this.$store.state.engagements }}</span></a
              >
            </li>

            <li
              v-if="this.$route.name == 'LeadDetail'"
              class="color-drop dropdown navbar"
            >
              <a
                class="colorsdrop dropdown-toggle"
                href="#"
                id="stickyDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span data-toggle="tooltip" title="Sticky Notes"
                  ><img class="img-fluid" src="../assets/img/ssBell.svg" alt=""
                /></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="stickyDropdown">
                <a
                  class="dropdown-item"
                  @click="addNewStickNote('#C3FEB9')"
                  href="#"
                  ><span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.9545 22.8098L22.8098 15.9545L17.1818 15.9545C16.504 15.9545 15.9545 16.504 15.9545 17.1818L15.9545 22.8098ZM-3.21874e-07 23.3182C-1.44108e-07 25.3516 1.64841 27 3.68182 27L14.7273 27C15.0528 27 15.3649 26.8707 15.5951 26.6405L26.6405 15.5951C26.8707 15.3649 27 15.0528 27 14.7273L27 3.68182C27 1.64841 25.3516 -3.08958e-07 23.3182 -1.31192e-07L3.68182 1.58547e-06C1.6484 1.76324e-06 -2.21631e-06 1.64841 -2.03854e-06 3.68182L-3.21874e-07 23.3182Z"
                        fill="#C3FEB9"
                      /></svg></span
                ></a>

                <a
                  class="dropdown-item"
                  @click="addNewStickNote('#FEF8B9')"
                  href="#"
                  ><span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.9545 22.8098L22.8098 15.9545L17.1818 15.9545C16.504 15.9545 15.9545 16.504 15.9545 17.1818L15.9545 22.8098ZM-3.21874e-07 23.3182C-1.44108e-07 25.3516 1.64841 27 3.68182 27L14.7273 27C15.0528 27 15.3649 26.8707 15.5951 26.6405L26.6405 15.5951C26.8707 15.3649 27 15.0528 27 14.7273L27 3.68182C27 1.64841 25.3516 -3.08958e-07 23.3182 -1.31192e-07L3.68182 1.58547e-06C1.6484 1.76324e-06 -2.21631e-06 1.64841 -2.03854e-06 3.68182L-3.21874e-07 23.3182Z"
                        fill="#C3FEB9"
                      /></svg></span
                ></a>

                <a
                  class="dropdown-item"
                  @click="addNewStickNote('#FED6B9')"
                  href="#"
                  ><span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.9545 22.8098L22.8098 15.9545L17.1818 15.9545C16.504 15.9545 15.9545 16.504 15.9545 17.1818L15.9545 22.8098ZM-3.21874e-07 23.3182C-1.44108e-07 25.3516 1.64841 27 3.68182 27L14.7273 27C15.0528 27 15.3649 26.8707 15.5951 26.6405L26.6405 15.5951C26.8707 15.3649 27 15.0528 27 14.7273L27 3.68182C27 1.64841 25.3516 -3.08958e-07 23.3182 -1.31192e-07L3.68182 1.58547e-06C1.6484 1.76324e-06 -2.21631e-06 1.64841 -2.03854e-06 3.68182L-3.21874e-07 23.3182Z"
                        fill="#C3FEB9"
                      /></svg></span
                ></a>

                <a
                  class="dropdown-item"
                  @click="addNewStickNote('#F4B9FE')"
                  href="#"
                  ><span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.9545 22.8098L22.8098 15.9545L17.1818 15.9545C16.504 15.9545 15.9545 16.504 15.9545 17.1818L15.9545 22.8098ZM-3.21874e-07 23.3182C-1.44108e-07 25.3516 1.64841 27 3.68182 27L14.7273 27C15.0528 27 15.3649 26.8707 15.5951 26.6405L26.6405 15.5951C26.8707 15.3649 27 15.0528 27 14.7273L27 3.68182C27 1.64841 25.3516 -3.08958e-07 23.3182 -1.31192e-07L3.68182 1.58547e-06C1.6484 1.76324e-06 -2.21631e-06 1.64841 -2.03854e-06 3.68182L-3.21874e-07 23.3182Z"
                        fill="#C3FEB9"
                      /></svg></span
                ></a>
              </div>
            </li>
            <div
              v-for="note in notes"
              :key="note.id"
              :style="{
                position: 'absolute',
                left: note.p_left,
                top: note.p_top,
              }"
              :id="'mydiv' + note.id"
              :class="'customsticky drag note_container' + note.id"
              @mouseenter="getStickyNoteId(note.id)"
            >
              <div :id="'mydiv' + note.id + 'header'">
                <div class="draggable-colors">
                  <div id="drag-color1" class="draggable ui-widget-content">
                    <div class="closeicon color1">
                      <p v-on:click="deletenotee(note.id)">
                        <img
                          class="img-fluid"
                          src="../assets/img/close.svg"
                          alt=""
                        />
                      </p>
                    </div>
                    <div class="drag-con">
                      <p>
                        <textarea
                          :class="'sticky-textarea note_text' + note.id"
                          @blur="updateStickyNote(note.id)"
                          class="form-control"
                          v-model="note.note_text"
                        ></textarea>
                      </p>
                      <!-- <p><input type="text" :value="note.note_text" :class="'note_text'+note.id" @blur="updateStickyNote(note.id)" class="form-control" /></p> -->
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="130"
                          height="134"
                          viewBox="0 0 130 134"
                          fill="none"
                        >
                          <g filter="url(#filter0_f)">
                            <path
                              d="M7.41211 28.7206H118.589L126 129.706L7.41211 123.221V28.7206Z"
                              fill="#A3A29C"
                            />
                          </g>
                          <path
                            d="M0 2C0 0.89543 0.89543 0 2 0H118.493C119.577 0 120.464 0.857209 120.493 1.94085C120.819 14.2323 122.607 81.2225 123.221 96.3529C123.735 109.027 124.003 118.49 124.101 122.303C124.128 123.347 123.347 124.226 122.306 124.321C117.265 124.782 102.812 126 90.7941 126C77.3965 126 14.0504 126 1.98698 126C0.882409 126 0 125.105 0 124V2Z"
                            :fill="note.color"
                          />
                          <defs>
                            <filter
                              id="filter0_f"
                              x="3.41211"
                              y="24.7206"
                              width="126.588"
                              height="108.985"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                              />
                              <feGaussianBlur
                                stdDeviation="2"
                                result="effect1_foregroundBlur"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <li v-if="btnstop">
              <a
                data-toggle="Record Video"
                @click="stopvideo"
                title="Record Video"
                class="support record-video"
                ><img
                  class="img-fluid"
                  src="../assets/img/video_cam-02.svg"
                  alt=""
              /></a>
            </li>
            <li v-if="btnstart">
              <a
                data-toggle="Record Video"
                @click="startRecordVideo"
                title="Record Video"
                class="support record-video"
                ><img
                  class="img-fluid"
                  src="../assets/img/video_cam-01.svg"
                  alt=""
              /></a>
            </li>
            <li>
              <a
                data-toggle="tooltip"
                title="Support"
                class="support"
                @click="supportWindowModalShow()"
                ><img class="img-fluid" src="../assets/img/support.svg" alt=""
              /></a>
            </li>
            <li
              v-if="
                ($route.name == 'LeadDetail' &&
                  this.$storage.get('auth').user.user_type == 1) ||
                this.$storage.get('auth').user.permissions.indexOf('17') != -1
              "
            >
              <a
                data-toggle="tooltip"
                title="Credit"
                @click="getCredit"
                class="support credit"
              >
                <img class="img-fluid" src="../assets/img/compare.svg" alt=""
              /></a>
            </li>
            <li>
              <a class="search-box">
                <div class="form-group">
                  <input
                    type="search"
                    v-on:keyup.enter="searchCustomer"
                    v-model="keyword"
                    class="form-control"
                    placeholder="Search..."
                    ref="searchbox"
                  />
                  <img
                    class="img-fluid sear-icon"
                    v-on:click="toggleHeaderSearchIcon"
                    src="../assets/img/search.svg"
                    alt=""
                  />
                </div>
              </a>
            </li>
            <li>
              <a @click="toggleNotitab" id="bel" class="bells"
                ><img
                  id="bel1"
                  class="img-fluid"
                  src="../assets/img/Bell.svg"
                  alt=""
                />
                <span id="bel2" v-if="count > 0">{{ count }}</span></a
              >
              <div class="notif-tabs">
                <ul
                  class="nav nav-tabs nav-justified"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      >Notifications / Leads</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <simplebar
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    style="max-height: 300px"
                  >
                    <!-- <router-link v-for="noti in notifications" :key="noti.id" :to="{ name: 'LeadDetail',params: { id: noti.data.id }}"> -->
                    <img
                      v-if="notoficationloader"
                      src="../assets/img/Spinner.gif"
                      class="img-fluid"
                      style="width: 104px; position: relative; left: 117px"
                    />
                    <div v-if="!notoficationloader">
                      <a
                        v-for="(noti, index) in notifications"
                        :key="index"
                        :id="'notification-item-' + noti.id"
                        href="#"
                        :class="noti.read_at == null ? 'newNoti' : ''"
                        style="position: relative;"
                      >
                        <div class="notif-irem"
                          @click.prevent="
                            updateNotiColor(
                              noti.id,
                              noti.data.noti_type,
                              noti.data.id,
                              noti
                            )
                          "
                          >
                          <a
                            v-if="
                              noti.data.type &&
                              noti.data.type == 'groupchatnoti'
                            "
                          >
                            <div class="noti-title" style="position:relative;">
                              <h3>{{ noti.data.name }}</h3>
                              <p>{{ noti.data.lead_type }}</p>
                              <p>{{ noti.created_at | formatenotidate }}</p>
                              <!-- <div @click="deleteNotification(noti.id)" class="position-absolute" style="top: 35%;right: 15px;width: 30px;">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                              </div> -->
                            </div>
                          </a>
                          <a
                            v-if="
                              noti.data.type &&
                              noti.data.type == 'singlechatnoti'
                            "
                          >
                            <div class="noti-title" style="position:relative;">
                              <h3>{{ noti.data.name }}</h3>
                              <p>{{ noti.data.lead_type }}</p>
                              <p>{{ noti.created_at | formatenotidate }}</p>
                              <!-- <div @click="deleteNotification(noti.id)" class="position-absolute" style="top: 35%;right: 15px;width: 30px;">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                              </div> -->
                            </div>
                            </a>

                          <a
                            v-if="!noti.data.type"
                          >
                            <div class="noti-title" style="position:relative;">
                              <h3>{{ noti.data.name }}</h3>
                              <p>{{ noti.data.lead_type }}</p>
                              <p>{{ noti.created_at | formatenotidate }}</p>
                              <!-- <div @click="deleteNotification(noti.id)" class="position-absolute" style="top: 35%;right: 15px;width: 30px;">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                              </div> -->
                            </div>
                          </a>
                        </div>
                        <div @click="deleteNotification(noti)" class="position-absolute" style="top: 35%;right: 15px;width: 30px;color: #3151a1;">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                          </svg>
                        </div>
                      </a>
                      <a class="btn" @click="loadMore">View All</a>
                      <div class="notif-irem" v-if="notifications.length == 0">
                        <p>No notification found</p>
                      </div>
                    </div>

                    <!-- <a
                      v-show="moreExists"
                      class="btn"
                      @click="loadMore(nextpage)"
                      >Load More</a
                    > -->
                    <!-- </router-link> -->
                  </simplebar>
                </div>
              </div>
            </li>
            <li class="acvive-user dropdown navbar">
              <a
                class="user-logout dropdown-toggle"
                href="#"
                id="profileDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  v-if="profileimg"
                  class="img-fluid"
                  :src="profileimg"
                  alt=""
                  style="width: 40px; height: 40px"
                />
                <img
                  v-else
                  class="img-fluid"
                  :src="require('../assets/img/profile.svg')"
                  alt=""
                />
                <span
                  class="user-active"
                  style="width: 15px !important; height: 15px !important"
                  :class="
                    $storage.get('auth').user.busy_status == 1
                      ? 'Away-now'
                      : 'user-active'
                  "
                ></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="profileDropdown">
                <a
                  class="dropdown-item atve"
                  href="#"
                  v-on:click="updateUserBusyStatus(0)"
                  >Active</a
                >
                <a
                  class="dropdown-item Away"
                  href="#"
                  v-on:click="updateUserBusyStatus(1)"
                  >Away</a
                >
                <router-link :to="{ name: 'Profile' }" class="dropdown-item"
                  >Edit profile</router-link
                >
                <!-- <a class="dropdown-item" href="#" v-on:click="editProfile()">Edit profile</a> -->
                <!-- <router-link :to="{ name: 'Logout' }" class="dropdown-item" >Logout</router-link> -->
                <a href="#" @click="logout" class="dropdown-item">Logout</a>
              </div>
            </li>
            <li v-if="this.$storage.get('auth').user.user_type != 0">
              <a
                class="lead-btn"
                href="#"
                data-toggle="modal"
                data-target="#addNewLeadModal"
                data-backdrop="static"
                data-keyboard="false"
                v-on:click="clearAutocomplete"
                >Add Leadsheet<img
                  class="img-fluid"
                  src="../assets/img/plus.png"
                  alt=""
              /></a>
            </li>
            <!-- <li v-if="this.$storage.get('auth').user.user_type == 0"><a class="lead-btn" href="#" v-on:click="addDealer">Add Dealer <img class="img-fluid" src="../assets/img/plus.png" alt=""></a></li> -->
            <li v-if="this.$storage.get('auth').user.user_type == 0">
              <router-link :to="{ name: 'addDealer' }" class="lead-btn">
                Add Dealer
                <img class="img-fluid" src="../assets/img/plus.png" alt=""
              /></router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- notification -->
    </div>
    <!-- Support Window Modal-->
    <div
      class="modal fade"
      id="supportWindowModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content support-window">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Get in Touch</h5>
            <a
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="cancelCrop"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="addSupportRequest" autocomplete="off">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <!-- Take email and message -->
                  <div class="form-group">
                    <!-- <label for="email">Email Address</label> -->
                    <input type="email" class="form-control" id="email" v-model="support.email" placeholder="Email address">
                    <div class="validate-error-message" v-if="$vd.support.email.$hasError === true">
                      {{ $vd.support.$errors.email[0] }}
                    </div>
                  </div>
                  <div class="form-group">
                    <!-- <label for="message">Message</label> -->
                    <textarea style="height: 220px;" class="form-control" id="message" v-model="support.message" row="6" placeholder="What can we help with?"></textarea>
                    <div class="validate-error-message" v-if="$vd.support.message.$hasError === true">
                      {{ $vd.support.$errors.message[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                  :disabled="supportBtnLoader"
                  type="submit"
                  class="rem-btn"
                >
                  <span v-if="supportBtnLoader"
                    ><span class="spinner-grow spinner-grow-sm"></span>
                    Sending...</span
                  >
                  <span v-if="!supportBtnLoader">Send Request</span>
                </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Add Lead Modal -->
    <div
      class="modal fade"
      id="addNewLeadModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Lead</h5>
            <a
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="cancelCrop"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="addNewLead" autocomplete="off">
            <div class="modal-body">
              <div v-show="doCrop">
                <vue-cropper
                  :aspect-ratio="1 / 1"
                  :view-mode="1"
                  ref="cropper"
                  :src="cropImgUrl"
                  alt="Source Image"
                ></vue-cropper>
              </div>
              <div v-show="!doCrop">
                <div class="user-img">
                  <div class="button-wrapper">
                    <span class="label">
                      <img class="img-fluid" v-bind:src="imgUrl" alt="" />
                    </span>
                    <input
                      type="file"
                      ref="file"
                      accept="image/*"
                      v-on:change="handleFileUpload"
                      class="upload upload-box"
                      placeholder="Upload File"
                    />
                  </div>
                  <!-- <div class="edit-pro">
                                    <img class="img-fluid" src="../assets/img/edit.svg" alt="">
                                </div> -->
                </div>
                <div class="add-member add-lead">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label class="containerw">
                        Individual
                        <input
                          type="radio"
                          value="individual"
                          v-model="lead_.utype"
                          name="utype"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="containerw">
                        Company
                        <input
                          type="radio"
                          checked
                          value="company"
                          v-model="lead_.utype"
                          name="utype"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="form-row">
                    <div
                      class="form-group col-md-12"
                      v-if="lead_.utype == 'company'"
                    >
                      <label>Company Name</label>
                      <!-- <vue-tel-input v-model="lead_.phone"   v-bind="{mode:'international',inputId:'lead_phone',enabledCountryCode:false,enabledFlags:false,maxLen:15,validCharactersOnly:true,placeholder:'Enter a phone number',disabledFetchingCountry:true,dropdownOptions:{ disabledDialCode: true, tabindex: 0 }}" ></vue-tel-input> -->
                      <input
                        type="text"
                        class="form-control"
                        v-model="lead_.company"
                      />
                      <p class="validate-error-message">{{ company_error }}</p>
                    </div>
                    <div class="form-group col-md-6">
                      <label>First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="lead_.fname"
                        autocomplete="stop"
                      />
                      <p class="validate-error-message">{{ fname_error }}</p>
                    </div>
                    <div class="form-group col-md-6">
                      <label>Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="lead_.lname"
                        autocomplete="stop"
                      />
                      <p class="validate-error-message">{{ lname_error }}</p>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Email Address</label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="lead_.email"
                      autocomplete="stop"
                    />
                    <div
                      class="validate-error-message"
                      v-if="addLeadError.status === true"
                    >
                      {{ addLeadError.message }}
                    </div>
                    <!-- <div class="validate-error-message" v-if="$vd.lead_.email.$hasError === true">{{$vd.lead_.$errors.email[0]}}</div> -->
                  </div>
                  <div class="form-group">
                    <label>Phone number</label>
                    <!-- <vue-tel-input v-model="lead_.phone"   v-bind="{mode:'international',inputId:'lead_phone',enabledCountryCode:false,enabledFlags:false,maxLen:15,validCharactersOnly:true,placeholder:'Enter a phone number',disabledFetchingCountry:true,dropdownOptions:{ disabledDialCode: true, tabindex: 0 }}" ></vue-tel-input> -->
                    <input
                      type="tel"
                      class="form-control"
                      v-model="lead_.phone"
                    />

                    <!-- <div class="validate-error-message" v-if="addLeadError.phone.status === true">{{addLeadError.phone.message}}</div> -->
                    <!-- <input type="text" class="form-control" v-model="lead_.phone" placeholder="+123-22-223-00"> -->
                    <!-- <div class="validate-error-message" v-if="$vd.lead_.phone.$hasError === true">{{$vd.lead_.$errors.phone[0]}}</div> -->
                  </div>

                  <div class="form-group">
                    <label>Lead Type</label>
                    <select class="form-control" v-model="lead_.type">
                      <option value="">Select type</option>
                      <option
                        v-for="type in LTypes"
                        :value="type"
                        :key="type.lt_id"
                      >
                        {{ type.lt_name }}
                      </option>
                    </select>
                    <div
                      class="validate-error-message"
                      v-if="$vd.lead_.type.$hasError === true"
                    >
                      {{ $vd.lead_.$errors.type[0] }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Lead Source</label>
                    <select class="form-control" v-model="lead_.source">
                      <option value="">Select source</option>
                      <option
                        v-for="source in LSources"
                        :value="source.ls_id"
                        :key="source.ls_id"
                      >
                        {{ source.ls_name }}
                      </option>
                    </select>
                    <div
                      class="validate-error-message"
                      v-if="$vd.lead_.source.$hasError === true"
                    >
                      {{ $vd.lead_.$errors.source[0] }}
                    </div>
                  </div>
                  <div class="form-group Vehicle">
                    <label>Vehicle of Interest</label>
                    <template v-if="IsV2 && IsV2TokenExist">
                      <autocomplete
                        ref="autocomplete"
                        method="post"
                        :request-headers="{
                          'Authorization': 'Bearer ' +  $storage.get('auth').user.dealer_autolink_v2_auth_token
                        }"
                        input-class="form-control border-0"
                        :source="
                          'https://api.dealersgear.com/api/crmInventory?limit=20&offset=0&keyword='
                        "
                        results-property="inventory"
                        :results-display="formattedAutocompleteDisplay"
                        @selected="selectedVOI"
                      >
                      </autocomplete>  
                    </template>
                    <template v-else>
                    <autocomplete
                      ref="autocomplete"
                      input-class="form-control border-0"
                      :source="
                        'https://autolinkme.com/webservices/crm/inventory?id=' +
                        $storage.get('auth').user.dealer_autolink_id +
                        '&limit=20&offset=0&status=active&search_by=value&search_string='
                      "
                      results-property="data"
                      :results-display="formattedAutocompleteDisplay"
                      @selected="selectedVOI"
                    >
                    </autocomplete>
                  </template>
                    <!-- <div class="validate-error-message" v-if="$vd.lead_.voi.$hasError === true">{{$vd.lead_.$errors.voi[0]}}</div> -->
                    <!-- <input type="search" v-model="lead_.voi" class="form-control" placeholder="Type of Interest">
                                    <img class="img-fluid Vehi-img" src="../assets/img/search.svg" alt=""> -->
                  </div>
                  <!-- <div class="form-group Vehicle">
                                    <label>Vehicle of Interest</label>
                                    <input type="search" v-model="lead_.voi" class="form-control" placeholder="Type of Interest">
                                    <img class="img-fluid Vehi-img" src="../assets/img/search.svg" alt="">
                                </div> -->
                  <div class="form-group">
                    <label>Trade-In VIN</label>
                    <input
                      type="text"
                      v-model="lead_.tradeIn"
                      class="form-control"
                    />
                    <div
                      class="validate-error-message"
                      v-if="$vd.lead_.tradeIn.$hasError === true"
                    >
                      {{ $vd.lead_.$errors.tradeIn[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <a href="#" class="add-btn"><img class="img-fluid" src="../assets/img/22. Delete.svg" alt=""> Cancel</a> -->
              <a
                v-if="doCrop"
                href="#"
                class="add-btn"
                data-dismiss="modal"
                v-on:click="cancelCrop"
              >
                Cancel</a
              >
              <button
                type="button"
                v-on:click="cropIt"
                class="rem-btn"
                v-if="doCrop"
              >
                Crop
              </button>
              <button
                v-if="!doCrop"
                :disabled="btnLoader"
                type="submit"
                class="rem-btn"
              >
                <span v-if="btnLoader"
                  ><span class="spinner-grow spinner-grow-sm"></span>
                  Loading...</span
                >
                <span v-if="!btnLoader"
                  ><img
                    class="img-fluid"
                    src="../assets/img/23. Close.svg"
                    alt=""
                  />
                  Add</span
                >
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Add Lead Modal -->

    <!-- Dealers Access Modal -->
    <div
      class="modal fade"
      id="dealerAccesssModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Dealers</h5>
            <a
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="cancelCrop"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="updateSelectedDealer" autocomplete="off">
            <div class="modal-body">
              <div>
                <div class="add-member add-lead">
                  <div class="form-group">
                    <label>Dealers</label>
                    <select class="form-control" v-model="selected_dealer">
                      <option value="">Choose Dealer</option>
                      <option
                        v-for="dealer in dealers"
                        :value="dealer"
                        :key="dealer.da_id"
                      >
                        {{ dealer.dl_name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                v-if="!doCrop"
                :disabled="btnLoader"
                type="submit"
                class="rem-btn"
              >
                <img
                  class="img-fluid"
                  src="../assets/img/23. Close.svg"
                  alt=""
                />
                Select
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Dealers Access Modal -->

    <!-- touch stats Modal -->
    <div
      class="modal fade"
      id="touchStats"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Touch Stats</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div
            class="container text-center"
            style="display: flex; align-items: flex-start"
          >
            <div class="appointments boxspace">
              <div class="accordion" id="accordionExample3">
                <div class="card">
                  <div aria-labelledby="headingOne3">
                    <div class="card-body">
                      <p>Call</p>
                      <p>{{ call }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="appointments boxspace">
              <div class="accordion" id="accordionExample3">
                <div class="card">
                  <div aria-labelledby="headingOne3">
                    <div class="card-body">
                      <p>Email</p>
                      <p>{{ email }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="appointments boxspace">
              <div class="accordion" id="accordionExample3">
                <div class="card">
                  <div aria-labelledby="headingOne3">
                    <div class="card-body">
                      <p>Text</p>
                      <p>{{ sms }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="appointments boxspace">
              <div class="accordion" id="accordionExample3">
                <div class="card">
                  <div aria-labelledby="headingOne3">
                    <div class="card-body">
                      <p>Facebook</p>
                      <p>{{ fb_touch }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="appointments boxspace">
              <div class="accordion" id="accordionExample3">
                <div class="card">
                  <div aria-labelledby="headingOne3">
                    <div class="card-body">
                      <p>Floor</p>
                      <p>{{ floor_touch }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- touch stats Modal -->

    <!-- Eng stats Modal -->
    <div
      class="modal fade"
      id="engStats"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Engagement Stats</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div
            class="container text-center"
            style="display: flex; align-items: flex-start"
          >
            <div class="appointments boxspace">
              <div class="accordion" id="accordionExample3">
                <div class="card">
                  <div aria-labelledby="headingOne3">
                    <div class="card-body">
                      <p>Call</p>
                      <p>{{ eng_call }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="appointments boxspace">
              <div class="accordion" id="accordionExample3">
                <div class="card">
                  <div aria-labelledby="headingOne3">
                    <div class="card-body">
                      <p>Email</p>
                      <p>{{ eng_email }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="appointments boxspace">
              <div class="accordion" id="accordionExample3">
                <div class="card">
                  <div aria-labelledby="headingOne3">
                    <div class="card-body">
                      <p>Text</p>
                      <p>{{ eng_sms }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="appointments boxspace">
              <div class="accordion" id="accordionExample3">
                <div class="card">
                  <div aria-labelledby="headingOne3">
                    <div class="card-body">
                      <p>Facebook</p>
                      <p>{{ fb_eng }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="appointments boxspace">
              <div class="accordion" id="accordionExample3">
                <div class="card">
                  <div aria-labelledby="headingOne3">
                    <div class="card-body">
                      <p>Floor</p>
                      <p>{{ floor_eng }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Eng stats Modal -->

    <!-- Record  Video  -->
    <div
      class="modal fade"
      id="recordVideo"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="container">
            <div class="row text-center">
              <div class="col-md-12">
                <p>Recorded Video Uploaded Successfully</p>
                <div class="timeline">
                  <button type="button" @click="copy" class="">
                    <span class="today">Copy Link</span>
                  </button>
                  <input type="hidden" id="testing-code" value="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Record  Video  -->

    <!-- Record  Video  -->
    <div
      class="modal fade"
      id="wait"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header"></div>
          <div class="container">
            <div class="row text-center">
              <div class="col-md-12">
                <div class="timeline">
                  <p>Video is being uploaded...</p>
                  <br /><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Record  Video  -->

    <!-- notification  modal  -->
    <div
      class="modal fade"
      id="notimodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header"></div>
          <div class="container">
            <div class="row" style="display: block;position:relative;">
              <div class="col-md-12"></div>
              <vue-tabs @tab-change="handleNotificationTabChange">
                <v-tab
                  v-if="this.$storage.get('auth').user.user_type == 2"
                  :title="'Email, SMS, Messenger' + '(' + this.count_noti + ')'"
                  :id="'Email, SMS, Messenger'"
                >
                  <div
                    class="tab-content"
                    id="myTabContent"
                    v-if="notiarray.length > 0"
                  >
                    <simplebar
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      style="max-height: 300px"
                    >
                      <a
                        v-for="notifi in notiarray"
                        :key="notifi.id"
                        href="#"
                        :class="notifi.read_at == null ? 'newNoti' : ''"
                      >
                        <div class="notif-irem" :id="'notification-item-'+notifi.id" style="position:relative;">
                          <a
                            :href="
                              '/lead-detail/' +
                              notifi.data.id +
                              '/' +
                              notifi.data.category +
                              '/' +
                              notifi.data.msg_id
                            "
                          >
                            <div class="noti-title">
                              <h3>{{ notifi.data.name }}</h3>
                              <p>{{ notifi.data.lead_type }}</p>
                              <p>{{ notifi.data.date }}</p>
                            </div>
                          </a>
                          <div @click="deleteNotification(notifi,'notiarray')" class="position-absolute" style="top: 35%;right: 15px;width: 30px;color: #3151a1;">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                          </div>
                        </div>
                      </a>

                      <center>
                        <a
                          v-show="moreExists"
                          style="cursor: pointer"
                          class="btn"
                          @click="handleloadmore(nextpage, 0)"
                          >Load More</a
                        >
                      </center>
                    </simplebar>
                  </div>

                  <p class="found" v-else>No Notification Found</p>
                </v-tab>

                <v-tab :title="'Lead Assigned' + '(' + count_lead + ')'" :id="'Lead Assigned'">
                  <div
                    class="tab-content"
                    id="myTabContent"
                    v-if="noti_lead_array.length > 0"
                  >
                    <simplebar
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      style="max-height: 300px"
                    >
                      <a
                        v-for="notifi in noti_lead_array"
                        :key="notifi.id"
                        href="#"
                        :class="notifi.read_at == null ? 'newNoti' : ''"
                      >
                        <div
                          class="notif-irem"
                          :id="'notification-item-'+notifi.id"
                          style="position:relative;"
                          v-if="notifi.notifiable_id == user"
                        >
                          <a :href="'/lead-detail/' + notifi.data.id">
                            <div class="noti-title">
                              <h3>{{ notifi.data.name }}</h3>
                              <p>{{ notifi.data.lead_type }}</p>
                              <p>{{ notifi.data.date }}</p>
                            </div>
                          </a>
                          <div @click="deleteNotification(notifi,'noti_lead_array')" class="position-absolute" style="top: 35%;right: 15px;width: 30px;color: #3151a1;">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <center>
                        <a
                          v-show="moreExistslead"
                          style="cursor: pointer"
                          class="btn"
                          @click="handleloadmorelead(nextpage, 1)"
                          >Load More</a
                        >
                      </center>
                    </simplebar>
                  </div>
                  <p class="found" v-else>No Notification Found</p>
                </v-tab>

                <v-tab :title="'New Lead' + '(' + count_new_lead + ')'" :id="'New Lead'">
                  <div
                    class="tab-content"
                    id="myTabContent"
                    v-if="noti_new_lead_array.length > 0"
                  >
                    <simplebar
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      style="max-height: 300px"
                    >
                      <a
                        v-for="notifi in noti_new_lead_array"
                        :key="notifi.id"
                        href="#"
                        :class="notifi.read_at == null ? 'newNoti' : ''"
                      >
                        <div
                          class="notif-irem"
                          :id="'notification-item-'+notifi.id"
                          style="position:relative;"
                          v-if="notifi.notifiable_id == user"
                        >
                          <a :href="'/lead-detail/' + notifi.data.id">
                            <div class="noti-title">
                              <h3>{{ notifi.data.name }}</h3>
                              <p>{{ notifi.data.lead_type }}</p>
                              <p>{{ notifi.data.date }}</p>
                            </div>
                          </a>
                          <div @click="deleteNotification(notifi,'noti_new_lead_array')" class="position-absolute" style="top: 35%;right: 15px;width: 30px;color: #3151a1;">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <center>
                        <a
                          v-show="moreExistsnewlead"
                          style="cursor: pointer"
                          class="btn"
                          @click="handleloadmorenewlead(newleadnextpage, 3)"
                          >Load More</a
                        >
                      </center>
                    </simplebar>
                  </div>
                  <p class="found" v-else>No Notification Found</p>
                </v-tab>

                <v-tab :title="'Alert' + '(' + count_alert + ')'" :id="'Alert'">
                  <div
                    class="tab-content"
                    id="myTabContent"
                    v-if="noti_alert_array.length > 0"
                  >
                    <simplebar
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      style="max-height: 300px"
                    >
                      <a
                        v-for="notifi in noti_alert_array"
                        :key="notifi.id"
                        href="#"
                        :class="notifi.read_at == null ? 'newNoti' : ''"
                      >
                        <div
                          class="notif-irem"
                          :id="'notification-item-'+notifi.id"
                          style="position:relative;"
                          v-if="notifi.notifiable_id == user"
                        >
                          <a :href="'/lead-detail/' + notifi.data.id">
                            <div class="noti-title">
                              <h3>{{ notifi.data.name }}</h3>
                              <p>{{ notifi.data.lead_type }}</p>
                              <p>{{ notifi.data.date }}</p>
                            </div>
                          </a>
                          <div @click="deleteNotification(notifi,'noti_alert_array')" class="position-absolute" style="top: 35%;right: 15px;width: 30px;color: #3151a1;">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <center>
                        <a
                          v-show="moreExistsalert"
                          style="cursor: pointer"
                          class="btn"
                          @click="handleloadmorealet(alertnextpage, 2)"
                          >Load More</a
                        >
                      </center>
                    </simplebar>
                  </div>
                  <p class="found" v-else>No Notification Found</p>
                </v-tab>

                <v-tab :title="'Past Notifications' + '(' + count_past + ')'" :id="'Past'">
                  <div
                    class="tab-content"
                    id="myTabContent"
                    v-if="noti_past_array.length > 0"
                  >
                    <simplebar
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      style="max-height: 300px"
                    >
                      <a
                        v-for="notifi in noti_past_array"
                        :key="notifi.id"
                        href="#"
                        :class="notifi.read_at == null ? 'newNoti' : ''"
                      >
                        <div
                          class="notif-irem"
                          :id="'notification-item-'+notifi.id"
                          style="position:relative;"
                          v-if="notifi.notifiable_id == user"
                        >
                          <a :href="'/lead-detail/' + notifi.data.id">
                            <div class="noti-title">
                              <h3>{{ notifi.data.name }}</h3>
                              <p>{{ notifi.data.lead_type }}</p>
                              <p>{{ notifi.data.date }}</p>
                            </div>
                          </a>
                        </div>
                      </a>
                      <center>
                        <a
                          v-show="moreExistspast"
                          style="cursor: pointer"
                          class="btn"
                          @click="handleloadmorepast(pastnextpage, 2)"
                          >Load More</a
                        >
                      </center>
                    </simplebar>
                  </div>
                  <p class="found" v-else>No Notification Found</p>
                </v-tab>
              </vue-tabs>

              <div @click="deleteAllNotification()" class="position-absolute" style="top:-15px;right: 15px;color: #fff; background-color:#3151a1;padding:10px;border-radius:10px;cursor:pointer;">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg> -->
                Clear All
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="permission"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Attention</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <div class="modal-body">
            <p style="margin-bottom: 20px">
              Lead created with ID {{ lead_id }}. This customer is already
              assigned to another agent. Please contact Manager
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              class="rem-btn close"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import moment from 'moment'
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import Autocomplete from "vuejs-auto-complete";
import VueDaval from "vue-daval";
import { Draggable } from "draggable-vue-directive";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import firebase from "firebase/app";
import "firebase/firebase-messaging";
//import sound from "../assets/beep/beep.mp3";
export default {
  name: "TheHeader",
  components: { VueCropper, simplebar, Autocomplete, VueTabs, VTab },
  directives: { Draggable },
  props: ["headerType"],
  mixins: [VueDaval],
  vdRules: {
    lead_: {
      // email: { type: 'email', checkAlias: (alias) => {
      //     //console.log(alias)
      //     var p = $("#addleadphonenumber").val()
      //     if(p != '' && p != undefined && p != 'undefined' && p != null ){
      //         return true
      //     }else{
      //         if(alias != '' && alias != undefined && alias != 'undefined' && alias != null ){
      //             return true
      //         }else{
      //             return 'This field is required.'
      //         }
      //     }
      // }},
      // phone: {checkAlias: (alias) => {
      //     var p = $("#addleademail").val()
      //     if(p != '' && p != undefined && p != 'undefined' && p != null ){
      //         return true
      //     }else{
      //         if(alias != '' && alias != undefined && alias != 'undefined' && alias != null ){
      //             return true
      //         }else{
      //             return 'This field is required.'
      //         }
      //     }
      // }},
      source: { required: true },
      type: { required: true }, //voi: {required: true},
      //tradeIn is not required but if enter should be valid vin
      tradeIn: {
        checkAlias: (alias) => {
          if (alias.length > 0) {
            return alias.length < 17 ? "Please enter a valid VIN number" : true;
          } else {
            return true;
          }
        },
      },
    },
    // editPro:{
    //     first_name:{ required: true },last_name:{ required: true },phone:{ required: true },
    //     password: { checkAlias: (alias) => {
    //         if (alias.length > 0) {
    //             return alias.length < 8 ? 'Minimun length is 8' : true;
    //         }else{
    //             return true;
    //         }
    //     }},cpassword: { equals: 'editPro.password' }
    // },
    note: {
      text: { required: true },
    },
    support : {
      email : {required : true},
      message : {required : true}
    },
  },
  //this.lead_ = {fname:'',lname:'',email:'',phone:'',source:'',type:'',voi:{},tradeDetail:'',tradeIn:'',img:''}
  data() {
    return {
      support: {
        email: "",
        message: "",
      },
      active_notification_tab: {
        index : 1,
        name : 'Lead Assigned'
      },
      supportBtnLoader: false,
      notoficationloader: false,
      floor_eng: "",
      floor_touch: "",
      lead_id: "",
      pos1: "",
      pos2: "",
      pos3: "",
      pos4: "",
      elmnt: "",
      profileimg: "",
      notiarray: [],
      noti_alert_array: [],
      noti_past_array: [],
      noti_lead_array: [],
      count_noti: "0",
      count_lead: "0",
      count_alert: "0",
      count_past: "0",
      btnstop: false,
      btnstart: true,
      keyword: "",
      count: 0,
      notifications: [],
      LSources: {},
      imgUrl: require("../assets/img/profile.svg"),
      cropImgUrl: null,
      user: "",
      doCrop: false,
      btnLoader: false,
      title: "",
      LTypes: {},
      lead_: {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        source: "",
        type: "",
        voi: "",
        tradeIn: "",
        tradeDetail: "",
        img: "",
        utype: "individual",
        company: "",
      },
      addLeadError: { status: false, message: "Please enter phone or email." },
      //editPro:{first_name:'',last_name:'',phone:'',password:'',cpassword:'',id:0},
      note: {
        text: "",
        color: "",
        lead_id: this.$route.params.id,
        dealer_id: this.$storage.get("auth").user.dealer_id,
      },
      stickyid: "",
      notes: [],
      nextpage: "",
      moreExists: "",
      dealers: [],
      selected_dealer: "",
      new_sec: { token: "", user: {} },
      fname_error: "",
      lname_error: "",
      company_error: "",
      call: "",
      email: "",
      sms: "",
      eng_call: "",
      eng_sms: "",
      eng_email: "",
      fb_eng: "",
      fb_touch: "",
      alertnextpage: "",
      pastnextpage: "",
      leadnextpage: "",
      newleadnextpage: "",
      noti_new_lead_array: [],
      count_new_lead: 0,
      moreExistslead: "",
      moreExistsalert: "",
      moreExistspast: "",
      moreExistsnewlead: "",
      isLocalNotificationAvailable : false
    };
  },
  computed:{
    IsV2(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_version){
          if(this.$storage.get('auth').user.dealer_autolink_version == 'v2'){
            return true;
          }
        }
      }
      return false;
    },
    IsV2TokenExist(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token){
          if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token != ''){
            return true;
          }
        }
      }
      return false;
    },
    V2AuthHeader(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token){
          if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token != ''){
            return {
              'Authorization' : 'Bearer ' + this.$storage.get('auth').user.dealer_autolink_v2_auth_token
            };
          }
        }
      }
      return {};
    }
  },
  created() {
    try {
      let localNotifications = localStorage.getItem('notifications');
      if(localNotifications !== null){
        localNotifications = JSON.parse(localNotifications);
        if(localNotifications.length){
          this.notifications = localNotifications;
          this.count = localStorage.getItem('notification_count');
          console.log("LocalNotificationAvailable");
          this.isLocalNotificationAvailable = true;
        }else{
          this.isLocalNotificationAvailable = false;
        }
      }
    } catch (error) {
      this.notoficationloader = true;
    }
    this.getCount();
    this.profileImage();
    this.getLeadSource();
    this.getLeadType();
    this.getNotifications();
    this.validateFields();
    this.loginProcess();
    if (this.$storage.get("auth").user.user_type == 1) {
      this.getAssignedDealers();
    }
    if(this.$storage.get('auth').user.user_type == 2){
      this.active_notification_tab.index = null;
      this.active_notification_tab.name = 'Email, SMS, Messenger';
    }
    if (this.$route.name == "LeadDetail") {
      this.getStickyNotes();
    }
    $("body").mouseup(function (e) {
      var container = $(".profile-img-arrow-down");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        if ($(".profile-img-arrow-down").hasClass("active") === true) {
          $(".profile-img-arrow-down").toggleClass("active");
        }
      }
    });
    //this.$socket.emit("add_user", this.$storage.get("auth").user.email);
    let vm = this;
    this.$root.$on("pTitle", function (data) {
      vm.title = data;
    });
    this.$root.$on("addleadsheet", function (data) {
      console.log(data);
      vm.lead_.fname = data.cus_fname;
      vm.lead_.lname = data.cus_lname;
      if (data.email) {
        vm.lead_.email = data.email;
      }
      if (data.phone) {
        vm.lead_.phone = data.phone;
      }
      if (data.img) {
        this.imgUrl = data.img;
      }
      $("#addNewLeadModal").modal("show");
    });

    this.$root.$on("fbmessage", function (data) {
      vm.count = data.count;
      // vm.getNotifications();
    });

    this.$root.$on("action", function (action, data) {
      if (action == "changeprofileimage") {
        vm.profileimg = data;
      }
      if (action == "notifications") {
        vm.getNotifications();
      }
    });

    // window.Echo.channel("Groupchat") //Should be Channel Name
    //   .listen("GroupChat", (e) => {
    //     console.log(e);
    //     if (e.type.type == "groupchat") {
    //       e.type.members.forEach((data) => {
    //         console.log(data);
    //         if (data == this.$storage.get("auth").user.email) {
    //           console.log("inside" + data);
    //           var message = "";
    //           message += "<li class='sender'>";
    //           message += "<span  class='name'>";
    //           message += "" + e.type.sender_name + "";
    //           message += "</span>";
    //           message += "<p>";
    //           if (e.type.message != "") {
    //             message += "<span>" + e.type.message + "</span><br>";
    //           }
    //           if (e.type.image != "") {
    //             message +=
    //               "<img src='" +
    //               e.type.image +
    //               "'  style='width:200px;height:200px'>";
    //           }
    //           message += "</p>";
    //           message += "</li>";
    //           $("#groupmessages_" + e.type.group_id).append(message);

    //           if ($(".media_" + e.type.group_id).length > 0) {
    //             $(".groups").prepend($(".media_" + e.type.group_id));
    //             $(".mark_" + e.type.group_id).show();
    //             // var count = $(".mark_" + e.type.group_id).text();
    //             // console.log(count);
    //             // count = eval(count) + 1;
    //             $(".mark_" + e.type.group_id).html(
    //               "<span style='  background: #19cd9d;padding: 0px 8px;border-radius: 9px;color: #fff;font-weight: 700;font-size: 12px;'></span>"
    //             );
    //           }
    //           var favicon = "../assets/img/newchaticon.png";
    //           $("#favicon").attr("href", favicon);
    //           if (this.$route.name == "Chat") {
    //             const audio = new Audio(sound);
    //             audio.play();
    //             axios
    //               .get("read/groupmessage/" + e.type.group_id, {
    //                 headers: {
    //                   Authorization:
    //                     "Bearer " + this.$storage.get("auth").token,
    //                 },
    //               })
    //               .then((res) => {
    //                 if (res.data.success) {
    //                   console.log(res.data.success);
    //                 }
    //               });
    //           }
    //           var div = $(".CommunicationGroupSection");
    //           div.scrollTop(div.prop("scrollHeight"));
    //         }
    //       });
    //     }
    //   });
  },

  /*sockets: {
    connected: function () {
      this.$socket.emit("add_user", this.$storage.get("auth").user.email);
      console.log("connected run");
    },
    new_notification: function (data) {
      console.log(data);
      this.getNotifications();
    },
  },*/
  mounted() {
    this.getPermissions();
    let marked_notifications = localStorage.getItem("marked_notifications");
    if (marked_notifications !== null) {
      marked_notifications = JSON.parse(marked_notifications);
      if (marked_notifications.length) {
        marked_notifications.forEach((notification) => {
          axios
          .get("update_notification_color/" + notification.id + "/" + notification.data.noti_type + "/" + notification.data.id, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            console.log(res); 
          })
          .catch((err) => {
            console.log(err);
          });
        });

        localStorage.removeItem("marked_notifications");
      }
    }
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip"]').on("click", function () {
      $(this).tooltip("hide");
    });
    window.Echo.channel("notiChannel") //Should be Channel Name
      .listen("NotiEvent", (e) => {
        console.log(e);
        if (e.action == this.$storage.get("auth").user.email) {
          this.count = e.count;
          //this.getNotifications();
          // var count = $("#bel2").text();
          // count = eval(count) + 1;
          // $("#bel2").text(count);
          //this.notifications = e.text;
        }
      });
    console.log("header component mounted");
  },
  updated() {
    let v = this;
    const messaging = firebase.messaging();
    messaging.onMessage(function (payload) {
      console.log({firebase:payload});
      if(payload.data){
        if (payload.data.receiver == v.$storage.get("auth").user.email) {
          var count = $("#bel2").text();
          count = eval(count) + 1;
          $("#bel2").text(count);
          try {
            v.notifications.unshift({
              dealer_id: v.$storage.get("auth").user.dealer_id,
              id: null,
              data : {
                date: moment().format('MM/DD/YYYY hh:mm'),
                id: payload.data.lead_id,
                lead_type: payload.notification.body,
                name: payload.notification.title,
                category : "",
                msg_id : "",
              },
              noti_type: 1,
              read_at : null,
              created_at :  moment().format('MM/DD/YYYY hh:mm'),
              notifiable_id : v.$storage.get("auth").user.id
            });
          } catch (error) {
            console.log({error});
          }
        }
  
        console.log(payload);
        if (
          payload.data.switchdealer == "true" &&
          payload.data.user_id == v.$storage.get("auth").user.id
        ) {
          axios
            .post(
              "updateAuth",
              { dealer_name: payload.data.dealer_name },
              {
                headers: {
                  Authorization: "Bearer " + v.$storage.get("auth").token,
                },
              }
            )
            .then((res) => {
              if (res.data.success) {
                //var token = v.$storage.get("auth").token;
                //res.data.success.token = token;
                v.$storage.clear();
  
                v.$storage.set("auth", res.data.success);
  
                axios.defaults.headers.common["Authorization"] =
                  res.data.success.token;
  
                location.reload(true);
  
                //var data = res.data.success
                //data.token = token
                //this.$storage.set('auth', res.data.success)
                //console.log(this.new_sec)
              } else {
                location.reload(true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (payload.data.chat == "group") {
          //const noteTitle = payload.notification.title;
          // const noteOptions = {
          //   body: payload.notification.body,
          //   icon: payload.notification.icon,
          // };
          // var notification1 = new Notification(noteTitle, noteOptions);
          // notification1.onclick = function (event) {
          //   notification1.close();
          //   console.log(event);
          //   v.$router.push("/chat");
          // };
          v.$root.$emit("action", "group", payload.data);
        } else if (payload.data.chat == "one to one") {
          // const noteTitle = payload.notification.title;
          // const noteOptions = {
          //   body: payload.notification.body,
          //   icon: payload.notification.icon,
          // };
          // var notification = new Notification(noteTitle, noteOptions);
          // notification.onclick = function (event) {
          //   notification.close();
          //   console.log(event);
          //   v.$router.push("/chat");
          // };
          v.$root.$emit("action", "one-to-one", payload.data);
        } else {
          v.$root.$emit("action", "getEmail");
          v.$root.$emit("action", "fbnotification", payload.data);
          // setTimeout(() => {
          //   console.log('getting notifications');
          //   v.getNotifications();
          // });
          // const noteTitle = payload.notification.title;
          // const noteOptions = {
          //   body: payload.notification.body,
          //   icon: payload.notification.icon,
          // };
          // var notification2 = new Notification(noteTitle, noteOptions);
          // notification2.onclick = function (event) {
          //   notification2.close();
          //   console.log(event);
          //   v.$router.push("/lead-detail/" + payload.data.lead_id);
          // };
        }
      }
    });
  },
  watch: {
    "lead_.utype": function () {
      this.validateFields();
    },
    "lead_.fname": function () {
      this.fname_error = "";
    },
    "lead_.lname": function () {
      this.lname_error = "";
    },
    "lead_.company": function () {
      this.company_error = "";
    },
  },
  methods: {
    supportWindowModalShow(){
      $("#supportWindowModal").modal("show");
    },
    loginProcess() {
      axios
        .get("assignnotification", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            let v = this;

            const messaging = firebase.messaging();
            messaging
              .requestPermission()
              .then(function () {
                return messaging.getToken();
              })
              .then(function (token) {
                console.log(token);
                if (token) {
                  if (res.data.success) {
                    if(v.$storage.get("auth").user.user_type == 2){
                      v.subscribeTokenToTopic(
                        token,
                        "agent" +
                          "_" +
                          v.$storage.get("auth").user.id +
                          "_" +
                          v.$storage.get("auth").user.dealer_id
                      );
                    }
                    if(v.$storage.get("auth").user.user_type == 1){
                      v.subscribeTokenToTopic(
                        token,
                        "manager" +
                          "_" +
                          v.$storage.get("auth").user.id +
                          "_" +
                          v.$storage.get("auth").user.dealer_id
                      );
                    }
                    if (res.data.success.facebook == 1) {
                      v.subscribeTokenToTopic(
                        token,
                        "facebook" +
                          "_" +
                          v.$storage.get("auth").user.id +
                          "_" +
                          v.$storage.get("auth").user.dealer_id
                      );
                    }
                    if (res.data.success.sms == 1) {
                      v.subscribeTokenToTopic(
                        token,
                        "sms" +
                          "_" +
                          v.$storage.get("auth").user.id +
                          "_" +
                          v.$storage.get("auth").user.dealer_id
                      );
                    }
                    if (res.data.success.email == 1) {
                      v.subscribeTokenToTopic(
                        token,
                        "email" +
                          "_" +
                          v.$storage.get("auth").user.id +
                          "_" +
                          v.$storage.get("auth").user.dealer_id
                      );
                    }
                    if (res.data.success.NewLead == 1) {
                      v.subscribeTokenToTopic(
                        token,
                        "NewLead" +
                          "_" +
                          v.$storage.get("auth").user.id +
                          "_" +
                          v.$storage.get("auth").user.dealer_id
                      );
                    }
                    if (res.data.success.alert == 1) {
                      v.subscribeTokenToTopic(
                        token,
                        "alert" +
                          "_" +
                          v.$storage.get("auth").user.id +
                          "_" +
                          v.$storage.get("auth").user.dealer_id
                      );
                    }
                    if (res.data.success.automatedtasks == 1) {
                      v.subscribeTokenToTopic(
                        token,
                        "automatedtasks" +
                          "_" +
                          v.$storage.get("auth").user.id +
                          "_" +
                          v.$storage.get("auth").user.dealer_id
                      );
                    }
                    if (res.data.success.leadassign == 1) {
                      v.subscribeTokenToTopic(
                        token,
                        "leadassign" +
                          "_" +
                          v.$storage.get("auth").user.id +
                          "_" +
                          v.$storage.get("auth").user.dealer_id
                      );
                    }
                    if (res.data.success.innercircle == 1) {
                      v.subscribeTokenToTopic(
                        token,
                        "innercircle" +
                          "_" +
                          v.$storage.get("auth").user.id +
                          "_" +
                          v.$storage.get("auth").user.dealer_id
                      );
                    }
                  }
                }
              })
              .catch(function (err) {
                console.log("User Chat Token Error" + err);
              });

          

            //this.$router.push('/dashboard');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotochat(link, groupid) {
      if (link == "?tab=chat") {
        window.location.href = "/chat" + link + "&userid=" + groupid;
      } else {
        window.location.href = "/chat" + link + "&groupid=" + groupid;
      }
    },
    profileImage() {
      axios
        .get("get/profile/image", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.profileimg = res.data.img;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPermissions() {
      axios
        .get("get_user_permissions", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.permissions) {
            this.$store.commit("updatepermission", res.data.permissions);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    subscribeTokenToTopic(token, topic) {
      fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
        method: "POST",
        headers: new Headers({
          Authorization: `key=AAAAG69RFgo:APA91bFImVgPFU7wMbLfrI4cUU_TPFlkJQgn4MLrKxbAaEHMH-LO0HwECVaFvr1ApLXb7-3CNaGaQetr0fQ6_W7YOwAzwL8-DFCFYf6oxTf8hmEVZ16jmPN2KzrZ5eyKG3IB_zfnmpyL`,
        }),
      })
        .then((response) => {
          if (response.status < 200 || response.status >= 400) {
            console.log(response.status, response);
          }
          console.log(`"${topic}" is subscribed`);
        })
        .catch((error) => {
          console.error(error.result);
        });
      return true;
    },
    updatetopics() {
      let v = this;
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(function () {
          return messaging.getToken();
        })
        .then(function (token) {
          console.log(token);
          if (token) {
            //save token in db
            axios
              .post(
                "update_token/",
                { token: token },
                {
                  headers: {
                    Authorization: "Bearer " + v.$storage.get("auth").token,
                  },
                }
              )
              .then((res) => {
                if (res.data.success == true) {
                  console.log("token");
                }
              })
              .catch((err) => {
                console.log(err);
              });
            if (v.$storage.get("auth").user.user_type == 1) {
              v.subscribeTokenToTopic(
                token,
                "manager_" +
                  v.$storage.get("auth").user.id +
                  "_" +
                  v.$storage.get("auth").user.dealer_id
              );
            }

            if (v.$storage.get("auth").user.user_type == 2) {
              v.subscribeTokenToTopic(
                token,
                "agent_" +
                  v.$storage.get("auth").user.id +
                  "_" +
                  v.$storage.get("auth").user.dealer_id
              );
            }
          }
        })
        .catch(function (err) {
          console.log("User Chat Token Error" + err);
        });
    },
    dragElement(id) {
      $(".note_text" + id).focus();
      $(".customsticky").css("z-index", "999");
      $("#mydiv" + id).css("z-index", "1000");
      this.elmnt = document.getElementById("mydiv" + id);
      this.elmnt.onmousedown = this.dragMouseDown;
      // (this.pos1 = 0), (this.pos2 = 0), (this.pos3 = 0), (this.pos4 = 0);
      // if (document.getElementById(this.elmnt.id + "header")) {
      //   /* if present, the header is where you move the DIV from:*/
      //   document.getElementById(this.elmnt.id + "header").onmousedown =
      //     this.dragMouseDown;
      // } else {
      //   /* otherwise, move the DIV from anywhere inside the DIV:*/

      // }
    },
    dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      document.onmouseup = this.closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = this.elementDrag;
    },
    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      this.pos1 = this.pos3 - e.clientX;
      this.pos2 = this.pos4 - e.clientY;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      // set the element's new position:
      this.elmnt.style.top = this.elmnt.offsetTop - this.pos2 + "px";
      this.elmnt.style.left = this.elmnt.offsetLeft - this.pos1 + "px";

      // console.log("left")
      // console.log(this.elmnt.style.left)
      //     console.log("top")
      // console.log(this.elmnt.style.top);
    },
    closeDragElement() {
      /* stop moving when mouse button is released:*/
      var position = {
        left: this.elmnt.style.left,
        top: this.elmnt.style.top,
      };
      this.onStickyNotePosChanged(position);
      document.onmouseup = null;
      document.onmousemove = null;
    },
    goToDesking() {
      this.$root.$emit("gotodesking", this.$route.params.id);
    },
    getCredit() {
      this.$toast.open({
        position: "top-right",
        message: "Please Wait",
        type: "info",
      });
      this.$root.$emit("creditModal");
    },
    handleloadmore(page, index) {
      axios
        .get(
          "get/notifications/" +
            index +
            "/" +
            this.$storage.get("auth").user.dealer_id +
            "?page=" +
            page,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            if (
              res.data.notifications.current_page <
              res.data.notifications.last_page
            ) {
              this.moreExists = true;
              this.nextpage = res.data.notifications.current_page + 1;
            } else {
              this.moreExists = false;
            }
            res.data.notifications.data.forEach((data) => {
              this.notiarray.push(data);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleloadmorealet(page, index) {
      axios
        .get(
          "get/notifications/" +
            index +
            "/" +
            this.$storage.get("auth").user.dealer_id +
            "?page=" +
            page,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            if (
              res.data.notifications.current_page <
              res.data.notifications.last_page
            ) {
              this.moreExistsalert = true;
              this.alertnextpage = res.data.notifications.current_page + 1;
            } else {
              this.moreExistsalert = false;
            }
            res.data.notifications.data.forEach((data) => {
              this.noti_alert_array.push(data);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleloadmorepast(page, index = 'all') {
      axios
        .get(
          "trashed/notifications/" +
            index +
            "/" +
            this.$storage.get("auth").user.dealer_id +
            "?page=" +
            page,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            if (
              res.data.notifications.current_page <
              res.data.notifications.last_page
            ) {
              this.moreExistspast = true;
              this.pastnextpage = res.data.notifications.current_page + 1;
            } else {
              this.moreExistspast = false;
            }
            res.data.notifications.data.forEach((data) => {
              this.noti_past_array.push(data);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleloadmorelead(page, index) {
      axios
        .get(
          "get/notifications/" +
            index +
            "/" +
            this.$storage.get("auth").user.dealer_id +
            "?page=" +
            page,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            if (
              res.data.notifications.current_page <
              res.data.notifications.last_page
            ) {
              alert();
              this.moreExistslead = true;
              this.leadnextpage = res.data.notifications.current_page + 1;
            } else {
              this.moreExistslead = false;
            }
            res.data.notifications.data.forEach((data) => {
              this.noti_lead_array.push(data);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleloadmorenewlead(page, index) {
      axios
        .get(
          "get/notifications/" +
            index +
            "/" +
            this.$storage.get("auth").user.dealer_id +
            "?page=" +
            page,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            if (
              res.data.notifications.current_page <
              res.data.notifications.last_page
            ) {
              this.moreExistsnewlead = true;
              this.newleadnextpage = res.data.notifications.current_page + 1;
            } else {
              this.moreExistsnewlead = false;
            }
            res.data.notifications.data.forEach((data) => {
              this.noti_new_lead_array.push(data);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    notificationbysms() {
      axios
        .get(
          "get/notifications/" +
            0 +
            "/" +
            this.$storage.get("auth").user.dealer_id,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            this.notiarray = res.data.notifications.data;
            this.count_noti = res.data.count;
            if (
              res.data.notifications.current_page <
              res.data.notifications.last_page
            ) {
              this.moreExists = true;
              this.nextpage = res.data.notifications.current_page + 1;
            } else {
              this.moreExists = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    notificationbyalert() {
      axios
        .get(
          "get/notifications/" +
            2 +
            "/" +
            this.$storage.get("auth").user.dealer_id,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            this.noti_alert_array = res.data.notifications.data;
            this.count_alert = res.data.count;
            if (
              res.data.notifications.current_page <
              res.data.notifications.last_page
            ) {
              this.moreExistsalert = true;
              this.alertnextpage = res.data.notifications.current_page + 1;
            } else {
              this.moreExistsalert = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    notificationbypast() {
      axios
        .get(
          "trashed/notifications/" +
            "all" +
            "/" +
            this.$storage.get("auth").user.dealer_id,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            this.noti_past_array = res.data.notifications.data;
            this.count_past = res.data.count;
            if (
              res.data.notifications.current_page <
              res.data.notifications.last_page
            ) {
              this.moreExistspast = true;
              this.pastnextpage = res.data.notifications.current_page + 1;
            } else {
              this.moreExistspast = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    notificationbylead() {
      axios
        .get(
          "get/notifications/" +
            1 +
            "/" +
            this.$storage.get("auth").user.dealer_id,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            this.noti_lead_array = res.data.notifications.data;
            this.count_lead = res.data.count;
            if (
              res.data.notifications.current_page <
              res.data.notifications.last_page
            ) {
              this.moreExistslead = true;
              this.leadnextpage = res.data.notifications.current_page + 1;
            } else {
              this.moreExistslead = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addSupportRequest(){
      this.supportBtnLoader = true;
      this.$vd.support.$validate().then(()=>{
        axios.post("support/requested",{
          email : this.support.email,
          message : this.support.message,
        },{
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        ).then((response)=>{
          console.log({response});
          this.supportBtnLoader = false;
          if(response.data.success){
            this.$toast.open({
              position: "bottom-right",
              message: response.data.success,
              type: "success",
            });
            this.support.email = "";
            this.support.message = "";
            this.$vd.support.$reset();
            $("#supportWindowModal").modal("hide");
          }
        }).catch(()=>{}).finally(()=>{});
      }).catch(()=>{
        this.supportBtnLoader = false;
      });
    },
    notificationbynewlead() {
      axios
        .get(
          "get/notifications/" +
            3 +
            "/" +
            this.$storage.get("auth").user.dealer_id,
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            this.noti_new_lead_array = res.data.notifications.data;
            this.count_new_lead = res.data.count;
            if (
              res.data.notifications.current_page <
              res.data.notifications.last_page
            ) {
              this.moreExistsnewlead = true;
              this.newleadnextpage = res.data.notifications.current_page + 1;
            } else {
              this.moreExistsnewlead = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEngModal() {
      axios
        .get("get/eng/stats/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.fb_eng = "";
            this.eng_call = res.data.call;
            this.eng_email = res.data.email;
            this.eng_sms = res.data.sms;
            this.fb_eng = res.data.fb_eng;
            this.floor_eng = res.data.floor_eng;
            $("#engStats").modal("show");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openTouchModal() {
      axios
        .get("get/touch/stats/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.status) {
            console.log(res.data.fb_touch);
            this.fb_touch = "";
            this.call = res.data.call;
            this.email = res.data.email;
            this.sms = res.data.sms;
            this.fb_touch = res.data.fb_touch;
            this.floor_touch = res.data.floor_touch;
            $("#touchStats").modal("show");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateFields() {
      if (this.lead_.utype == "company") {
        if (this.lead_.fname == "") {
          this.fname_error = "This field is required";
        }
        if (this.lead_.lname == "") {
          this.lname_error = "This field is required";
        }
        if (this.lead_.company == "") {
          this.company_error = "This field is required";
        }
      }
      if (this.lead_.utype == "individual") {
        if (this.lead_.fname == "") {
          this.fname_error = "This field is required";
        }
        if (this.lead_.lname == "") {
          this.lname_error = "This field is required";
        }
      }
    },

    updateSelectedDealer(dealer) {
      axios
        .post("updateSelectedDealer", dealer, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            //var token = this.$storage.get("auth").token;
            //res.data.success.token = token;
            // console.log(v.new_sec);
            let v = this;
            const messaging = firebase.messaging();
            messaging
              .requestPermission()
              .then(function () {
                return messaging.getToken();
              })
              .then(function (token) {
                console.log(token);
                if (token) {
                  v.subscribeTokenToTopic(
                    token,
                    "manager" +
                      "_" +
                      v.$storage.get("auth").user.id +
                      "_" +
                      v.$storage.get("auth").user.dealer_id
                  );

                  res.data.noti.forEach((data) => {
                    v.subscribeTokenToTopic(
                      token,
                      data +
                        "_" +
                        v.$storage.get("auth").user.id +
                        "_" +
                        v.$storage.get("auth").user.dealer_id
                    );
                  });
                }
              })
              .catch(function (err) {
                console.log("User Chat Token Error" + err);
              });

            this.$storage.clear();

            this.$storage.set("auth", res.data.success);

            axios.defaults.headers.common["Authorization"] =
              res.data.success.token;

            location.reload(true);

            // var token = this.$storage.get("auth").token;
            // this.new_sec.token = token;
            // this.new_sec.user = res.data.success;
            // this.$storage.clear();
            // setTimeout(() => {
            //   this.$storage.set("auth", this.new_sec);
            //   location.reload(true);
            // }, 500);

            //var data = res.data.success
            //data.token = token
            //this.$storage.set('auth', res.data.success)
            //console.log(this.new_sec)
          } else {
            location.reload(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAssignedDealers() {
      axios
        .get("assigned_dealers", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.dealers = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async logout() {
      localStorage.setItem('notifications',null);
      localStorage.setItem('notification_count',null);
      let user = this.$storage.get("auth").user;
      let user_id = user.id;
      axios
        .post("logout", {
          user_id: user_id,
        })
        .then(async (res) => {
          if (res.data.success) {
            this.$store.state.alertFilter = "today";
            this.$store.state.from = "";
            this.$store.state.to = "";
            this.$store.state.taskFilter = "month";
            this.$store.state.task_from = "";
            this.$store.state.task_to = "";
            this.$store.state.appointFilter = "month";
            this.$store.state.appoint_from = "";
            this.$store.state.appoint_to = "";
            this.$store.state.leadFilter = "";
            this.$store.state.lead_from = "";
            this.$store.state.lead_to = "";
            this.$storage.clear();
            
            try {
              // Perform your user logout logic here, e.g., sign out the user
              await firebase.auth().signOut();

              // After the user is signed out, delete the Firebase Installation ID
              const installations = firebase.installations();
              await installations.delete();

              // Check if the browser supports service workers
              if ('serviceWorker' in navigator) {
                // Get the service worker registration
                const registrations = await navigator.serviceWorker.getRegistrations();
                if (registrations) {
                  // Unregister the service workers
                  await registrations.forEach(async (worker)=>{await worker.unregister()});
                  console.log('Service worker unregistered.');
                }
              }

            } catch (error) {
              console.error('Logout error:', error);
            }

            window.location.href = "/login";
          }
        })
        .catch((err) => {
          console.log(err);
          this.$storage.clear();
          window.location.href = "/login";
        });
    },
    callService() {
      this.$confirm({
        title: `Are you sure?`,
        message: "You mark this lead as a service?",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            //console.log(member)
            axios
              .get("call_service/" + this.$route.params.id, {
                headers: {
                  Authorization: "Bearer " + this.$storage.get("auth").token,
                },
              })
              .then((res) => {
                if (res.data.success == true) {
                  this.$router.replace("/leads");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        },
      });
    },
    loadMore() {
      this.notificationbylead();
      this.notificationbyalert();
      this.notificationbysms();
      this.notificationbynewlead();
      this.notificationbypast();
      $("#notimodal").modal("show");
    },
    getStickyNoteId(id) {
      this.stickyid = id;
      this.dragElement(id);
    },
    getStickyNotes() {
      axios
        .get("sticky_notes/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.notes = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateStickyNote(id) {
      var text = $(".note_text" + id).val();
      axios
        .post(
          "update_sticky_note",
          { id: id, text: text, lead_id: this.$route.params.id },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          this.$root.$emit("action", "new_activity");
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deletenotee(id) {
      axios
        .get("delete_sticky_note/" + id + "/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.status == "true") {
            this.$root.$emit("action", "new_activity");
            $(".note_container" + id).remove();
            if (res.data.noti_emails.length > 0) {
              //let vm = this;
              //$.each(res.data.noti_emails, function (key, value) {
              //  vm.$socket.emit("send_noti", { receiver: value });
              //});
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onStickyNotePosChanged: function (absolutePosition) {
      console.log(absolutePosition);
      if (
        absolutePosition.left !== undefined &&
        absolutePosition.left != null &&
        absolutePosition.left != "" &&
        absolutePosition.left != "undefined"
      ) {
        axios
          .post(
            "update_sticky_note_position",
            {
              left: absolutePosition.left,
              top: absolutePosition.top,
              id: this.stickyid,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            }
          )
          .then((response) => {
            if (response.data.status == "true") {
              console.log("success");
            }
            if (response.data.status == "false") {
              console.log("false");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    addNewStickNote(color) {
      //alert(color)
      axios
        .post(
          "add_stickynote/" + this.$route.params.id,
          { color: color },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          console.log(res.data.data);
          if (res.data.status == "true") {
            this.$root.$emit("action", "new_activity");
            this.notes.push(res.data.data);
            if (res.data.noti_emails.length > 0) {
              //let vm = this;
              //$.each(res.data.noti_emails, function (key, value) {
              //vm.$socket.emit("send_noti", { receiver: value });
              //});
            }
          }
          if (res.data.status == "false") {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // addNewStickNote(){
    //     this.$vd.note.$validate().then(() => {
    //         axios
    //         .post("add_stickynote", this.note, {
    //             headers: {
    //                 Authorization: "Bearer " + this.$storage.get("auth").token,
    //             },
    //         })
    //         .then((res) => {
    //             if (res.data.status == "true") {
    //                 this.note.text = "";
    //                 this.$root.$emit("action", "loadsticknotes");
    //                 $("#stickymodal").modal("hide");
    //             }
    //             if (res.data.status == "false") {
    //                 alert(res.data.message);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    //     })
    //     .catch(() => {
    //         console.log(this.$vd.addApt.$errors);
    //     });
    // },

    // Old
    updateNotiColor(id, type, leadid,noti) {
      $(".notiifcation_" + id).removeClass("newNoti");
      this.notifications = this.notifications.map((item) => {
        if (item.id == id) {
          item.color = type;
        }
        return item;
      });
      localStorage.setItem('notifications',JSON.stringify(this.notifications));
      localStorage.setItem('marked_notifications',JSON.stringify([noti]));
      if(noti.data.type && noti.data.type == 'groupchatnoti'){
        this.gotochat(noti.data.link, noti.data.groupid);
      }

      if(noti.data.type && noti.data.type == 'singlechatnoti'){
        this.gotochat(noti.data.link, noti.data.userid);
      }

      if(!noti.data.type){
        this.$router.push({
          name: "LeadDetail",
          params: {
            id: noti.data.id,
            notification_type: noti.data.category,
            notification_id: noti.data.msg_id,
          },
        });
      }
    },
    handleNotificationTabChange(tabIndex, tab_, _tab){
      tabIndex = tabIndex + 1;
      _tab;
      switch (tab_.id) {
        case 'Lead Assigned':
            this.active_notification_tab.index = 1;
            this.active_notification_tab.name = 'Lead Assigned';
          break;

        case 'New Lead':
            this.active_notification_tab.index = 3;
            this.active_notification_tab.name = 'New Lead';
          break;
        
        case 'Alert':
            this.active_notification_tab.index = 2;
            this.active_notification_tab.name = 'Alert';
          break;
        case 'Past Notifications':
            this.active_notification_tab.index = 4;
            this.active_notification_tab.name = 'Past Notifications';
          break;
      
        default:
            this.active_notification_tab.index = null;
            this.active_notification_tab.name = null;
          break;
      }
    },
    deleteAllNotification(){
      if(this.active_notification_tab.index){
        this.$toast.open({
          position: "bottom-right",
          message: "Deleting "+this.active_notification_tab.name+" Notification",
          type: "info",
        });

        axios.get("delete_all_notification/"+this.active_notification_tab.index+"/"+this.$storage.get("auth").user.dealer_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            switch (this.active_notification_tab.name) {
              case 'Lead Assigned':
                  this.noti_lead_array = [];
                  this.count_lead = 0;
                break;

              case 'New Lead':
                  this.noti_new_lead_array = [];
                  this.count_new_lead = 0;
                break;
              
              case 'Alert':
                  this.noti_alert_array = [];
                  this.count_alert = 0;
                break;
            }
          }
          this.$toast.open({
            position: "bottom-right",
            message: "Notifications Deleted",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
      }
    },
    deleteNotification(noti,type=null){
      this.$toast.open({
        position: "bottom-right",
        message: "Deleting Notification",
        type: "info",
      });
      if(noti.read_at == null){
        this.count = this.count - 1;
      }
      axios
        .get("delete_notification/" + noti.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            console.log(type);
            if(type){
              switch(type){
                case 'noti_alert_array':
                  this.noti_alert_array = this.noti_alert_array.filter((item) => item.id != noti.id);
                  this.count_alert = this.count_alert - 1;
                  break;
                case 'noti_lead_array':
                  this.noti_lead_array = this.noti_lead_array.filter((item) => item.id != noti.id);
                  this.count_lead = this.count_lead - 1;
                  break;
                case 'noti_new_lead_array':
                  this.noti_new_lead_array = this.noti_new_lead_array.filter((item) => item.id != noti.id);
                  this.count_new_lead = this.count_new_lead - 1;
                  break;
                default:
                  this.notiarray = this.notiarray.filter((item) => item.id != noti.id);
                  this.count_noti = this.count_noti - 1;
                  break;
              }
            }else{
              this.notiarray = this.notiarray.filter((item) => item.id != noti.id);
              this.count_noti = this.count_noti - 1;
              $('#notification-item-'+noti.id).remove();
            }
            this.$toast.open({
              position: "bottom-right",
              message: "Notification Deleted",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openStickyModal(color) {
      $("#stickymodal").modal("show");
      this.note.color = color;
    },
    // addDealer(){
    //     this.$root.$emit("action", "addDealerModal");
    // },
    // addNewStickNote(){
    //     this.$vd.note.$validate().then(() => {
    //         axios
    //         .post("add_stickynote", this.note, {
    //             headers: {
    //                 Authorization: "Bearer " + this.$storage.get("auth").token,
    //             },
    //         })
    //         .then((res) => {
    //             if (res.data.status == "true") {
    //                 this.note.text = "";
    //                 this.$root.$emit("action", "loadsticknotes");
    //                 $("#stickymodal").modal("hide");
    //             }
    //             if (res.data.status == "false") {
    //                 alert(res.data.message);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    //     })
    //     .catch(() => {
    //         console.log(this.$vd.addApt.$errors);
    //     });
    // },
    // editProfile(){
    //     axios
    // 	.get('user',{headers: {Authorization:'Bearer '+this.$storage.get('auth').token}})
    // 	.then( res => {
    //         if(res.data){
    //             let data = res.data
    //             this.editPro.first_name = data.first_name
    //             this.editPro.last_name = data.last_name
    //             this.editPro.phone = data.phone
    //             this.editPro.id = data.id
    //             $("#updateProfileModal").modal('show')
    //         }
    // 	})
    // 	.catch(err =>{
    // 		console.log(err)
    // 	})
    // },
    // updateProfile(){
    //     this.$vd.editPro.$validate().then(() => {
    //         axios
    //         .post('update_profile',this.editPro,{headers: {Authorization:'Bearer '+this.$storage.get('auth').token}})
    //         .then( res => {
    //             if(res.data.success){
    //                 this.editPro.password = ''
    //                 this.editPro.cpassword = ''
    //                 $("#updateProfileModal").modal('hide')

    //                 var auth = this.$storage.get('auth')
    //                 auth.user.name = res.data.success
    //                 this.$storage.set('auth', auth)
    //             }
    //         })
    //         .catch(err =>{
    //             console.log(err)
    //         })
    //     }).catch(() => {
    //         console.log("Invalid")
    //     })
    // },
    updateTitle(value) {
      this.title = value;
    },
    // emitIt() {
    //   $('[data-toggle="tooltip"]').tooltip();
    //   if (this.$storage.get("auth").user.email == "simon@gmail.com") {
    //     this.$socket.emit("send_noti", { receiver: "sales@dealersgear.com" });
    //   } else {
    //     this.$socket.emit("send_noti", { receiver: "simon@gmail.com" });
    //   }
    // },
    clearAutocomplete() {
      this.lead_ = {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        source: "",
        type: "",
        voi: "",
        tradeIn: "",
        tradeDetail: "",
        img: "",
        utype: "individual",
        company: "",
      };
      $(".autocomplete__inputs input").val("");
    },
    updateUserBusyStatus(status) {
      axios
        .get("update_user_busy_status/" + status, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            var auth = this.$storage.get("auth");
            if (status == 1) {
              $(".user-active").addClass("Away-now");
              auth.user.busy_status = 1;
            } else if (status == 0) {
              $(".user-active").removeClass("Away-now");
              auth.user.busy_status = 0;
            }
            this.$storage.set("auth", auth);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCount() {
      if(this.isLocalNotificationAvailable == false){
        this.notoficationloader = true;
      }
      axios
        .get("notifications/" + this.$storage.get("auth").user.dealer_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            setTimeout(() => {
              if (res.data.count) {
                this.count = res.data.count;
              }
              if (res.data.text) {
                this.notifications = res.data.text;
              }
              this.notoficationloader = false;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNotifications() {
      //this.notoficationloader = true;
      axios
        .get("notifications/" + this.$storage.get("auth").user.dealer_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            setTimeout(() => {
              this.notoficationloader = false;
              if (res.data.text) {
                this.notifications = res.data.text;
                localStorage.setItem('notifications',JSON.stringify(res.data.text));
              }
              if (res.data.count) {
                this.count = res.data.count;
                console.log("count",res.data.count);
                localStorage.setItem('notification_count',res.data.count);
              }


              if (res.data.user) {
                this.user = res.data.user;
              }
            });

            // console.log(res.data.text.data);

            //console.log("data" + res.data.text.data);
            // console.log(this.notifications);
            // if (res.data.text.current_page < res.data.text.last_page) {
            //   this.moreExists = true;
            //   this.nextpage = res.data.text.current_page + 1;
            // } else {
            //   this.moreExists = false;
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleNotitab() {
      if(this.count == 0){
        $("#notimodal").modal("show");
      }
      if ($(".notif-tabs.opens").length == 0) {
        this.getNotifications();
      }
      // this.markAsRead();
    },
    //toggleActiveStatus(){
    // if($(".profile-img-arrow-down").hasClass("active") === false){
    //     this.markAsRead()
    // }else{

    // }
    //$(".profile-img-arrow-down").toggleClass("active");
    //},
    toggleSidebar(where) {
      if (where == "web") {
        $(".menu-area").toggleClass("menu-full");
        $(".hide-item").toggleClass("show-item");
        $(".big-logo").toggleClass("show-logo");
        $(".audits-completed").toggleClass("menu-full-body");
      } else if (where == "mob") {
        $(".menu-area").toggleClass("mobile");
        $(".audits-completed").removeClass("menu-full-body");
        $(".menu-area").removeClass("menu-full");
        $(".hide-item").removeClass("show-item");
        $(".big-logo").removeClass("show-logo");
      }
    },
    toggleHeaderSearchIcon() {
      this.$refs.searchbox.focus();
      $(".search-box").toggleClass("full-search-box");
      $(".search-box .form-control").toggleClass("full-search-control");
    },
    markAsRead() {
      axios
        .get("mark_as_read", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.count = 0;
          if (res.data) {
            this.count = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addNewLead() {
      this.$vd.lead_
        .$validate()
        .then(() => {
          if (
            this.lead_.email == "" ||
            this.lead_.email == undefined ||
            this.lead_.email == "undefined" ||
            this.lead_.email == null
          ) {
            if (
              this.lead_.phone == "" ||
              this.lead_.phone == undefined ||
              this.lead_.phone == "undefined" ||
              this.lead_.phone == null
            ) {
              this.addLeadError.status = true;
              return false;
            } else {
              this.addLeadError.status = false;
            }
          } else {
            this.addLeadError.status = false;
          }

          this.btnLoader = true;
          //if(this.lead_.voi != '' && this.lead_.voi != undefined && this.lead_.voi != 'undefined' && this.lead_.voi != null ){
          if (
            this.lead_.tradeIn != "" &&
            this.lead_.tradeIn != undefined &&
            this.lead_.tradeIn != "undefined" &&
            this.lead_.tradeIn != null
          ) {
            axios
              .get(
                "https://autolinkme.com/webservices/crm/vin_decoder?id=" +
                  this.$storage.get("auth").user.dealer_id +
                  "&status=active&vin=" +
                  this.lead_.tradeIn
              )
              .then((res) => {
                if (res.data.status) {
                  this.lead_.tradeDetail = res.data.data;
                  //console.log(this.lead_.tradeDetail)
                  this.addNewLeadProcess();
                } else {
                  alert("Invalid Trade In VIN number");
                  this.btnLoader = false;
                }
              })
              .catch((err) => {
                console.log(err);
                this.btnLoader = false;
              });
          } else {
            if (this.lead_.utype == "individual") {
              if (this.lead_.fname == "" || this.lead_.lname == "") {
                this.validateFields();
                this.btnLoader = false;
                return false;
              }
            }
            if (this.lead_.utype == "company") {
              if (
                this.lead_.fname == "" ||
                this.lead_.lname == "" ||
                this.lead_.company == ""
              ) {
                this.validateFields();
                this.btnLoader = false;
                return false;
              }
            }

            this.addNewLeadProcess();
          }
          // }else{
          //     alert("Select Vehicle of Interest.")
          //     this.btnLoader = false
          // }
        })
        .catch(() => {});
    },
    addNewLeadProcess() {
      axios
        .post("add_new_lead", this.lead_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res.data);
          if (res.data.error) {
            this.$toast.open({
              position: "top-right",
              message: res.data.error,
              type: "info",
            });
            this.btnLoader = false;
            return false;
          }
          if (res.data.success) {
            this.$root.$emit("action", "new_activity");
            this.lead_id = res.data.lead_id;
            if (res.data.hasAccess == false) {
              this.btnLoader = false;
              $("#addNewLeadModal").modal("hide");
              $("#permission").modal("show");
              return false;
            }
            $("#addNewLeadModal").modal("hide");
            if (res.data.customer == true) {
              this.$router.push(
                "/lead-detail/" +
                  res.data.lead_id +
                  "?customer=" +
                  res.data.customer
              );
              this.btnLoader = false;
            } else {
              this.$router.push("/lead-detail/" + res.data.lead_id);
            }

            // if(window.location.pathname != '/leads'){
            //     this.$router.push('/leads')
            // }else{
            //     this.$root.$emit('newLead', 'added')
            //     this.btnLoader = false
            // }
            this.lead_ = {
              fname: "",
              lname: "",
              email: "",
              phone: "",
              source: "",
              type: "",
              voi: "",
              tradeDetail: "",
              tradeIn: "",
              img: "",
            };
          }
        })
        .catch((err) => {
          console.log(err);
          this.btnLoader = false;
        });
    },
    getLeadSource() {
      axios
        .get("dealer/lead/sources", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.LSources = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeadType() {
      axios
        .get("lead_types")
        .then((res) => {
          if (res.data.success) {
            this.LTypes = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleFileUpload(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgUrl = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.doCrop = true;
      //this.imgUrl = URL.createObjectURL(this.$refs.file.files[0]);
    },
    cropIt() {
      this.imgUrl = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.lead_.img = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.doCrop = false;
    },
    cancelCrop() {
      this.doCrop = false;
    },
    searchCustomer() {
      if (
        this.keyword != "" &&
        this.keyword != undefined &&
        this.keyword != "undefined" &&
        this.keyword != null
      ) {
        this.$router.push({
          path: "/customers",
          query: { keyword: this.keyword },
        });
        if (window.location.pathname == "/customers") {
          this.$root.$emit("action", "updateCustomers");
        }
      }
    },
    formattedAutocompleteDisplay(result) {
      return (
        result.year +
        " " +
        result.make +
        " " +
        result.model +
        " - " +
        result.vin
      );
    },
    selectedVOI(result) {
      console.log(result.selectedObject);
      this.lead_.voi = result.selectedObject;
    },
    startRecordVideo() {
      this.startRecording();
    },
    stopvideo() {
      this.recorder.stop();
      this.stream.getVideoTracks()[0].stop();
      this.stream.getAudioTracks()[0].stop();
      this.btnstop = false;
      this.btnstart = true;
    },
    async startRecording() {
      let videoStramConstraints = {
        audio: { echoCancellation: true },
        video: { mediaSource: "screen" },
      };
      const displayStream = await navigator.mediaDevices.getDisplayMedia(
        videoStramConstraints
      );

      let audioStreamConstraints = {
        audio: { echoCancellation: true },
        video: false,
      };
      const voiceStream = await navigator.mediaDevices.getUserMedia(
        audioStreamConstraints
      );
      let tracks = [
        ...displayStream.getTracks(),
        ...voiceStream.getAudioTracks(),
      ];

      this.stream = new MediaStream(tracks);

      this.recorder = new MediaRecorder(this.stream);

      const chunks = [];
      this.recorder.ondataavailable = (e) => chunks.push(e.data);
      this.btnstop = true;
      this.btnstart = false;
      this.recorder.onstop = (e) => {
        console.log(e);
        // $("#wait").modal({ backdrop: "static", keyboard: false });
        this.$toast.open({
          position: "top-right",
          message: "Video is being uploaded.Please wait...",
          type: "info",
        });
        const completeBlob = new Blob(chunks, { type: this.mimeType });
        this.src = URL.createObjectURL(completeBlob);

        // Upload Functionality Starts
        let uploadFormData = new FormData();
        uploadFormData.append("recordedvideo", completeBlob);
        uploadFormData.append("mp4_title", "Test File Upload");

        axios
          .post("/uploadmp4video", uploadFormData, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((response) => {
            if (response.data.status) {
              $("#testing-code").val(response.data.video_link);
              $("#recordVideo").modal("show");
              $("#wait").modal("hide");
              this.$root.$emit("action", "refresvideos");
            } else {
              alert(response.data.status);
            }
          })
          .catch(function (error) {
            alert(error);
          });
        // Upload functionality Ends
        console.log(e);
      };

      this.recorder.start();
    },
    copy() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log(msg);
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style>
.autocomplete .autocomplete__icon img {
  position: relative;
  top: -3px;
}
vue-tel-input .vti__dropdown {
  display: none;
}
.record-video {
  cursor: pointer;
}
.timeline {
  margin-top: 30px;
  margin-bottom: 20px;
}
.found {
  padding: 148px;
  margin-left: 97px;
}
.modal-dialog {
  max-width: 670px;
}
.credit {
  cursor: pointer;
}
.boxspace {
  margin-left: 13px;
  width: 200px;
}

.form-control:focus {
  color: #6d778e;
  background-color: #fff;
  border-color: inherit;
  outline: inherit;
  box-shadow: none;
  border: 1px solid #ced4da;
  box-sizing: border-box;
}
</style>

